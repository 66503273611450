import styled from '@emotion/styled';

const Button = styled.button`
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.proPrimary};
    background-color: ${({ theme }) => theme.proPrimary};
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.25rem;
    padding: 1.5rem 2.5rem;
    margin-top: 1rem;
    &:disabled {
        opacity: 0.65;
    }
    &:active {
        opacity: 0.8;
    }
    & + button {
        margin-left: 1rem;
    }
`;

export default Button;
