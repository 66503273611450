import { AppDocument } from '../Types';
import constants from './constants';

export function getDeviceId() {
    return (document as AppDocument).DEVICE_ID?.toLowerCase() || '';
}

export function getSerialNo() {
    return (document as AppDocument).SERIAL_NO?.toLowerCase() || '';
}

export function getDeviceToken() {
    return (document as AppDocument).DEVICE_TOKEN || '';
}

export function getApiEndpoint() {
    return (document as AppDocument).API_ENDPOINT || 'https://api.snackpass.co';
}

export function getShortId() {
    if (process.env.REACT_APP_ENV === 'local') {
        return constants.debugShortId;
    }
    const deviceId = getDeviceId();
    const parts = deviceId?.split("-") ?? ["N/A"]
    const shortId = parts.length ? parts[parts.length - 1].toUpperCase() : "N/A";

    return shortId;
}

export function getDeviceInfo() {
    let deviceId = '';
    let serialNo = '';
    const deviceToken = getDeviceToken();

    if (process.env.REACT_APP_ENV === 'local') {
        deviceId = constants.debugSerial;
        serialNo = constants.debugSerial;
    } else {
        deviceId = getDeviceId();
        serialNo = getSerialNo();
    }

    return { deviceId, serialNo, deviceToken };
}
