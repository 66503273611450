import Icons from './Icons';

type Props = {
    name: Icons;
    size: number | string;
    color: string;
    viewBox?: [number, number, number, number];
};

const SvgIcon: React.FC<Props> = ({ size, color, name, viewBox }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={viewBox?.toString().replace(/,/g, ' ') || '0 0 512 512'}
            style={{ flexShrink: 0 }}
        >
            <path fill={color} d={name} />
        </svg>
    );
};
export default SvgIcon;
