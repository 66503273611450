import { Theme, ThemeProvider } from '@emotion/react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import store from './modules/Redux/store';
import ErrorBoundaryFallback from './ui/pages/ErrorBoundaryFallback';
import Routing from './ui/pages/Routing';
import { LightColors } from './ui/theme';
import './ui/theme/emotion.d';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

function App() {
    
    const adjustedTheme = (ancestorTheme: Theme) => ({
        ...ancestorTheme,
    });

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <ThemeProvider theme={LightColors}>
                        <ThemeProvider theme={adjustedTheme}>
                            <Routing />
                        </ThemeProvider>
                    </ThemeProvider>
                </ErrorBoundary>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

export default App;
