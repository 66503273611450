import styled from '@emotion/styled';
import { QRCodeSVG } from 'qrcode.react';

export const Subcontainer = styled.div`
    border: 2px solid ${({ theme }) => theme.divider};
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 1rem;
    width: auto;
`;

export const VerticalDivider = styled.span`
    margin: 0 1rem;
    border-left: 2px solid ${({ theme }) => theme.divider};
    width: 1px;
    height: 100%;
`;

export const CodeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const Code = styled.span`
    color: ${({ theme }) => theme.proPrimary};
    font-family: Monaco, 'Lucida Console', monospace;
    font-size: 3rem;
    font-weight: 600;
    margin: 0.5rem 0;
    letter-spacing: 0.25rem;
    font-variant-numeric: slashed-zero;
`;

// just adding this to give the button some space and keep the text above it
// spaced similarly as when the Subcontainer is shown
export const ButtonContainer = styled.div`
    margin: 8rem;
`;

export const StyledQRCode = styled(QRCodeSVG)`
    width: 12rem;
    height: 12rem;
`;

export const ProgressContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12rem;
    margin: 4rem 0;
`

export const FailureOrSuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 12rem;
    margin: 4rem 0;
`