import styled from '@emotion/styled';

export const Title = styled.h1`
    font-weight: 800;
    font-size: 2.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.primaryText};
`;

export const SubTitle = styled.p`
    font-weight: 500;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.secondaryText};
`;
