import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const Title = styled.h1`
  font-weight: 800;
  font-size: 2.25rem;
  line-height: 2.8125rem;
  margin-top: 2.25rem;
  margin-bottom: 1.5rem;
`;

export const Subcontainer = styled.div`
  border: 2px solid ${({ theme }) => theme.divider};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding: 0.375rem 1.5rem;
  width: 35rem;
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.secondaryText};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0.375rem 0;
`;

export const Text = styled.p`
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin: 0.375rem 0;
`;

export const Button = styled.button`
  border: 2px solid ${({ theme }) => theme.proPrimary};
  border-radius: 16px;
  background-color: ${({ theme }) => theme.proPrimary};
  color: white;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.75rem;
  width: 16rem;
`;
