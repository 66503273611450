import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getShortId } from '../../modules/Utils/helpers';

const ShortIdWrapper = styled.span<ShortIdProps>`
    border: 2px solid ${({ theme }) => theme.divider};
    border-radius: 16px;
    color: ${({ theme }) => theme.secondaryText};
    font-family: Monaco, 'Lucida Console', monospace;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.1;
    padding: 0.75rem;
    ${({ position }) =>
        position === 'absolute' &&
        css`
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        `}
`;

type ShortIdProps = {
    position?: 'absolute' | 'relative';
    hideLabel?: boolean;
};

const ShortId = ({
    position = 'absolute',
    hideLabel = false,
}: ShortIdProps) => {
    const shortId = getShortId();
    if (!shortId || shortId === '') {
        return null;
    }
    return (
        <ShortIdWrapper position={position}>
            {hideLabel ? null : 'SnackID'} {shortId}
        </ShortIdWrapper>
    );
};

export default ShortId;
