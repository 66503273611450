import styled from '@emotion/styled';

const Container = styled.div`
    padding: 2rem;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    user-select: none;
    position: relative;
    text-align: center;
`;

export default Container;
