import { User } from 'firebase/auth';

export type FirebaseUser = User | null;

export type IOrderSource = '' | 'Kiosk' | 'App' | 'Web';
export type IOrderStatus = 'Ready' | 'In Progress' | 'Not Started';
export type IOrder = {
    id: string;
    customerFirstName: string;
    orderNo: string;
    orderSource: IOrderSource;
    orderState: IOrderStatus;
};

export enum TransactionSource {
    App = 'app',
    Kiosk = 'kiosk',
    Online = 'online',
}

export enum Screen {
    EnterPhone,
    Verify,
}

export type AppDocument = Document & {
    API_ENDPOINT?: string;
    DEVICE_ID?: string;
    SERIAL_NO?: string;
    DEVICE_TOKEN?: string;
    DEVICE_UUID?: string;
};

export enum CommandState {
    Unknown = "Unknown",
    Queued = 'Command Queued',
    Acknowledged = 'Command Acknowledged',
    Success = 'Command Success',
    Failure = 'Command Failure'
}

export enum ProgressState {
    None = 'None',
    InProgress = 'InProgress',
    Success = 'Success',
    Failure = 'Failure'
}