import axios from 'axios';
import { getApiEndpoint } from 'src/modules/Utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { CommandState } from 'src/modules/Types';

const endpoint = getApiEndpoint();

type DeviceInstallProgress = {
    state: CommandState;
}

function getDeviceInstallProgressBySerial(serial: string) {
    return axios.get<DeviceInstallProgress>(`${endpoint}/api/v4/devices/install-progress/${serial}`)
}

type Options = {
    serial: string;
    refetchInterval(state?: string): number;
}

export function useDeviceCommandList(options: Options) {
    const { serial, refetchInterval: refetchWhen } = options

    return useQuery({
        queryKey: [serial],
        queryFn: () => getDeviceInstallProgressBySerial(serial),
        refetchInterval: (data) => refetchWhen(data?.data.state),
        networkMode: "always",
        refetchIntervalInBackground: true,
        enabled: Boolean(serial)
    })
}