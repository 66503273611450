import { Theme } from "@emotion/react";
import { SystemColors } from "@snackpass/design-system";

const {
  v2: {
    spBlue50,
    candy50,
    salt100,
    salt80,
    salt60,
    salt50,
    salt30,
    salt20,
    salt10,
  },
} = SystemColors;

export const LightColors: Theme = {
  surface: salt10.light,
  background: salt20.light,
  proPrimary: candy50.light,
  primary: spBlue50.light,
  primaryText: salt80.light,
  secondaryText: salt60.light,
  placeholderText: salt50.light,
  divider: salt30.light,
  white: salt10.light,
};

export const DarkColors: Theme = {
  surface: salt20.dark,
  background: salt10.dark,
  proPrimary: candy50.dark,
  primary: spBlue50.dark,
  primaryText: salt80.dark,
  secondaryText: salt60.dark,
  placeholderText: salt50.dark,
  divider: salt30.dark,
  white: salt100.dark,
};
