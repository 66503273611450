import { Global } from '@emotion/react';
import { useMemo } from 'react';
import RegisterScreen from '../RegisterScreen';

const Routing = () => {
    const globalStyles = useMemo(() => {
        const { height, width, orientation } = window.screen;
        const isLandscape = orientation?.type?.includes('landscape');
        const largerDimension = isLandscape ? width : height;
        // 18px at 1440px screen width for portrait
        let desiredRatio = 17 / 1440;

        if (isLandscape) {
            // 14px at 1920px screen width for landscape
            desiredRatio = 19 / 1920;
        }

        return {
            html: {
                fontSize: `${Math.floor(largerDimension * desiredRatio)}px`,
            },
        };
    }, []);

    return (
        <>
            <Global styles={globalStyles} />
            <RegisterScreen />
        </>
    );
};

export default Routing;
