const constants = {
    debugSerial: 'POSDEBUG',
    debugShortId: 'AB0DE',
    dashboardDevicesURL: process.env.REACT_APP_DASHBOARD_URL
        ? `${process.env.REACT_APP_DASHBOARD_URL}/devices`
        : process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:3001/devices'
        : process.env.REACT_APP_ENV === 'development'
        ? 'https://dashboard-staging.snackpass.co/devices'
        : 'https://dashboard.snackpass.co/devices',
};

export default constants;
