import { useTheme } from '@emotion/react';
import { Container, LoadingIndicator, SpeedTest, SubTitle, Title } from 'src/ui/components';
import constants from '../../../modules/Utils/constants';
import { getDeviceInfo, getShortId } from 'src/modules/Utils/helpers';
import { Icons, SvgIcon } from '../../components';
import * as S from './styles';
import { useEffect, useRef, useState } from 'react';
import { CommandState, ProgressState } from 'src/modules/Types';
import {  useDeviceCommandList } from 'src/modules/Hooks/useDeviceCommandList';

const POLL_INTERVAL_MS = 2 * 1000; // 10 seconds
const SUCCESS_POLL_INTERVAL_MS = 60 * 1000; // 1 minute
const FAILED_TRANSITION_MS = 8 * 1000; // 8 seconds

const stateIsInProgress = (state: string) => state === CommandState.Queued || state === CommandState.Acknowledged;
const stateIsTerminating = (state: string) => state === CommandState.Failure || state === CommandState.Success;

const transitionTo = (prev: ProgressState, next: string): ProgressState => {
    // Idle -> Queued|Acknowledged
    if (prev === ProgressState.None && stateIsInProgress(next)) {
        return ProgressState.InProgress;
    }

    // Queued|Acknowledged -> Success|Failure
    if (prev === ProgressState.InProgress && stateIsTerminating(next)) {
        return next === CommandState.Success ? ProgressState.Success : ProgressState.Failure;
    }

    // Failure -> Queued|Acknowledged
    if (prev === ProgressState.Failure && stateIsInProgress(next)) {
        return ProgressState.InProgress;
    }

    // Success -> Success|Failure (refetch)
    if (prev === ProgressState.Success && stateIsTerminating(next)) {
        return ProgressState.Success;
    }

    // Failure -> Success|Failure (refetch)
    if (prev === ProgressState.Failure && stateIsTerminating(next)) {
        return ProgressState.Failure;
    }

    // Queued|Acknowledged -> Queued|Acknowledged
    if (prev === ProgressState.InProgress && stateIsInProgress(next)) {
        return ProgressState.InProgress;
    }

    return ProgressState.None;
};

const Setup = () => {
    const setupCode = getShortId();
    const { deviceId, serialNo } = getDeviceInfo();

    const snackTvSetupUrl = `${constants.dashboardDevicesURL}?setup=true&setupCode=${setupCode}&deviceId=${deviceId}&deviceSerial=${serialNo}`;

    const onTapSetupLink = () => {
        // Make it easier to test...
        process.env.REACT_APP_ENV === 'local' && alert(snackTvSetupUrl);
    };

    return (
        <S.Subcontainer>
            <S.StyledQRCode
                onClick={onTapSetupLink}
                value={snackTvSetupUrl}
            />
            <S.VerticalDivider />
            <S.CodeWrapper>
                <SubTitle>Setup Code</SubTitle>
                <S.Code>{setupCode}</S.Code>
            </S.CodeWrapper>
        </S.Subcontainer>
    );
};

const InProgress = () => {
    return (
        <S.ProgressContainer>
            <LoadingIndicator />
            <SubTitle>Setup in Progress</SubTitle>
        </S.ProgressContainer>
    );
};

const SuccessMessage = () => (
    <SubTitle style={{ fontSize: '1.65rem', fontWeight: 600 }}>
        Application Download Succeeded
    </SubTitle>
);

const FailureMessage = () => (
    <>
        <SubTitle style={{ fontSize: '1.65rem', fontWeight: 600 }}>
            Application Download Failed
        </SubTitle>
        <SubTitle style={{ marginTop: '0.725rem' }}>
            please check internet connection and try again
        </SubTitle>
    </>
);

const FailureOrSuccess = ({ progress, setProgress }: {
    progress: string,
    setProgress: (progress: ProgressState) => void;
}) => {
    const timeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (progress === ProgressState.Failure) {
            // show failure message then show qr code again
            timeout.current = setTimeout(() => setProgress(ProgressState.None), FAILED_TRANSITION_MS);
        }

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [setProgress, progress]);

    return (
        <S.FailureOrSuccessContainer>
            {progress === ProgressState.Success ? <SuccessMessage /> : <FailureMessage />}
        </S.FailureOrSuccessContainer>
    );
};

const RegisterScreen = () => {
    const theme = useTheme();
    const { serialNo } = getDeviceInfo();

    const [progress, setProgress] = useState<ProgressState>(ProgressState.None);

    const { data: command } = useDeviceCommandList({
        serial: serialNo,
        refetchInterval(state?: string): number {
            if (state && transitionTo(progress, state) === ProgressState.Success) {
                return SUCCESS_POLL_INTERVAL_MS
            }
            return POLL_INTERVAL_MS
        }
    })

    useEffect(() => {
        setProgress(
            transitionTo(progress, command?.data.state ?? CommandState.Unknown)
        );
    }, [command, progress]);

    return (
        <Container>
            <SvgIcon
                name={Icons.scan_frame}
                size={'3rem'}
                color={theme.proPrimary}
                viewBox={[0, 0, 48, 48]}
            />
            <Title>Welcome to Snackpass</Title>
            <SubTitle>
                Scan this QR code or visit <b>snk.co/setup</b>
            </SubTitle>
            {progress === ProgressState.None ? <Setup /> : null}
            {progress === ProgressState.InProgress ? <InProgress /> : null}
            {progress === ProgressState.Success || progress === ProgressState.Failure ?
                <FailureOrSuccess progress={progress} setProgress={setProgress} /> : null}
            <SpeedTest />
        </Container>
    );
};

export default RegisterScreen;
