import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';

type CFLocation = {
    iata: string;
    region: string;
    city: string;
    cca2: string;
    lat: number;
    lon: number;
};

type LatencyData = {
    min: number;
    max: number;
    avg: number;
    med: number;
    jit: number;
};

type ResourceTimings = {
    connect: number;
    domainLookup: number;
    duration: number;
    fetchStart: number;
    redirect: number;
    roundTrip: number;
    transferTime: number;
    responseStart: number;
    serverTime: number;
    startTime: number;
    ttfb: number;
    ping: number;
};

type SpeedTestData = {
    location?: CFLocation;
    latencies?: LatencyData;
    down100kB?: number[];
    down1Mb?: number[];
    down10Mb?: number[];
    up10kB?: number[];
    up100kB?: number[];
    up1Mb?: number[];
    server?: string;
    latency?: string;
    jitter?: string;
    download?: string;
    upload?: string;
};

export const useSpeedTest = (): [() => void, SpeedTestData] => {
    const [data, setData] = useState<SpeedTestData>({});

    const run = () => {
        fetchEdgeLocation().then(location =>
            setData(cur => ({ ...cur, location }))
        );

        const kB = 1024;
        const Mb = kB * kB;
        measureLatency()
            .then(latencies => setData(cur => ({ ...cur, latencies })))
            .then(() => measureDownload(100 * kB, 5))
            .then(down100kB => setData(cur => ({ ...cur, down100kB })))
            .then(() => measureDownload(1 * Mb, 4))
            .then(down1Mb => setData(cur => ({ ...cur, down1Mb })))
            .then(() => measureDownload(10 * Mb, 3))
            .then(down10Mb => setData(cur => ({ ...cur, down10Mb })))
            .then(() => measureUpload(10 * kB, 5))
            .then(up10kB => setData(cur => ({ ...cur, up10kB })))
            .then(() => measureUpload(10 * Mb, 4))
            .then(up100kB => setData(cur => ({ ...cur, up100kB })))
            .then(() => measureUpload(10 * Mb, 3))
            .then(up1Mb => setData(cur => ({ ...cur, up1Mb })))
            .then(() =>
                setData(cur => ({
                    server: `${cur?.location?.city} (${cur?.location?.iata})`,
                    latency: `${cur?.latencies?.avg?.toFixed(2)} ms`,
                    jitter: `${cur?.latencies?.jit?.toFixed(2)} ms`,
                    download: `${percentile([
                        ...(cur?.down100kB ?? []),
                        ...(cur?.down1Mb ?? []),
                        ...(cur?.down10Mb ?? []),
                    ])?.toFixed(1)} Mbps`,
                    upload: `${percentile([
                        ...(cur?.up10kB ?? []),
                        ...(cur?.up100kB ?? []),
                        ...(cur?.up1Mb ?? []),
                    ])?.toFixed(1)} Mbps`,
                }))
            );
    };

    return [run, data];
};

const fetchEdgeLocation = async (): Promise<CFLocation | undefined> => {
    const locations = await axios.get('https://speed.cloudflare.com/locations');
    const data = locations.data as CFLocation[];
    let colo = locations.headers['cf-meta-colo'];

    if (!colo) {
        const ray = locations.headers['cf-ray']?.split('-');
        if (ray?.length === 2) {
            colo = ray[1];
        }
    }

    if (!colo) {
        const { data } = await axios.get(
            'https://speed.cloudflare.com/cdn-cgi/trace'
        );
        colo =
            (data as string)
                .split('\n')
                .map(line => line.split('='))
                .find(pair => pair[0] === 'colo')?.[1] ?? '';
    }

    return data.find(d => d.iata === colo);
};

const request = async (
    {
        method = 'get',
        url,
    }: {
        method: 'get' | 'post';
        url: string;
    },
    data = ''
): Promise<ResourceTimings | undefined> => {
    performance?.clearResourceTimings();

    let res: AxiosResponse;
    switch (method) {
        case 'get':
            res = await axios.get(url);
            break;
        case 'post':
            res = await axios.post(url, data);
            break;
    }

    // This is only available from official speed.cloudflare.com workers.
    // If we host our own, it won't be available or calculated differently.
    const serverTime = parseFloat(res.headers['server-timing'].slice(22));

    const resources = performance?.getEntriesByType('resource');
    const t = resources.find(
        entry =>
            entry.name === url && entry instanceof PerformanceResourceTiming
    ) as PerformanceResourceTiming | undefined;

    return t
        ? {
              connect: t.connectEnd - t.connectStart,
              domainLookup: t.domainLookupEnd - t.domainLookupStart,
              duration: t.duration,
              fetchStart: t.fetchStart,
              redirect: t.redirectEnd - t.redirectStart,
              roundTrip: t.responseEnd - t.requestStart,
              transferTime: t.responseEnd - t.responseStart,
              responseStart: t.responseStart,
              serverTime,
              startTime: t.startTime,
              ttfb: t.responseStart - t.requestStart,
              ping: t.responseStart - t.requestStart - serverTime,
          }
        : undefined;
};

const download = (bytes: number) =>
    request({
        method: 'get',
        url: `https://speed.cloudflare.com/__down?bytes=${bytes}`,
    });

const upload = (bytes: number) =>
    request(
        {
            method: 'post',
            url: `https://speed.cloudflare.com/__up`,
        },
        '0'.repeat(bytes)
    );

const min = (values: number[]) => Math.min(...values);

const max = (values: number[]) => Math.max(...values);

const average = (values: number[]) => {
    let total = 0;

    for (let i = 0; i < values.length; i += 1) {
        total += values[i];
    }

    return total / values.length;
};

const median = (values: number[]) => {
    const half = Math.floor(values.length / 2);

    values.sort((a, b) => a - b);

    if (values.length % 2) return values[half];

    return (values[half - 1] + values[half]) / 2;
};

const percentile = (values: number[], percentile = 0.9) => {
    values.sort((a, b) => a - b);

    const pos = (values.length - 1) * percentile;
    const base = Math.floor(pos);
    const rest = pos - base;

    if (values[base + 1] !== undefined) {
        return values[base] + rest * (values[base + 1] - values[base]);
    }

    return values[base];
};

// Average distance between consecutive latency measurements...
const jitter = (values: number[]) => {
    let jitters = [];

    for (let i = 0; i < values.length - 1; i += 1) {
        jitters.push(Math.abs(values[i] - values[i + 1]));
    }

    return average(jitters);
};

const measureLatency = async (): Promise<LatencyData> => {
    const measurements: number[] = [];

    for (let i = 0; i < 20; i += 1) {
        await download(1000).then(
            res => {
                res && measurements.push(res.ping ?? 0);
            },
            console.error
        );
    }

    return {
        min: min(measurements),
        max: max(measurements),
        avg: average(measurements),
        med: median(measurements),
        jit: jitter(measurements),
    };
};

const calcSpeed = (bytes: number, durationMs: number) =>
    (bytes * 8) / (durationMs / 1000) / 1e6;

const measureDownload = async (
    bytes: number,
    iterations = 1
): Promise<number[]> => {
    const measurements: number[] = [];

    for (let i = 0; i < iterations; i += 1) {
        await download(bytes).then(
            res => {
                res && measurements.push(calcSpeed(bytes, res.transferTime));
            },
            console.error
        );
    }

    return measurements;
};

const measureUpload = async (
    bytes: number,
    iterations = 1
): Promise<number[]> => {
    const measurements: number[] = [];

    for (let i = 0; i < iterations; i += 1) {
        await upload(bytes).then(
            res => {
                res && measurements.push(calcSpeed(bytes, res.serverTime));
            },
            console.error
        );
    }

    return measurements;
};
