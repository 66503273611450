import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useSpeedTest } from 'src/modules/Hooks/useSpeedTest';
import LoadingIndicator from './LoadingIndicator';
import { SubTitle } from './Typography';

const Label = styled.div`
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: ${({ theme }) => theme.secondaryText};
`;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Card = styled.div`
    text-align: left;
    border: 1px solid ${({ theme }) => theme.divider};
    border-radius: 1rem;
    margin: 0.5rem;
    padding: 1rem;
`;

const StyledNumber = styled.h1`
    font-weight: 800;
    font-size: 2rem;
    color: ${({ theme }) => theme.primaryText};
`;

const StyledUnit = styled.small`
    padding-left: 0.25rem;
    font-weight: 400;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.secondaryText};
`;

enum ValuesToRender {
    download = 'download',
    upload = 'upload',
    latency = 'latency',
}

type ValuesType = keyof typeof ValuesToRender;

const SpeedTest = (): JSX.Element => {
    const [runSpeedTest, data] = useSpeedTest();

    useEffect(() => {
        runSpeedTest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderCard = (key: ValuesType) => {
        // split the values into the number and the unit, ie "431.2 Mbps" => ['431.2', 'Mbps'] so we
        // can style the text pieces separately
        const dataPoint = data[key]?.split(' ');

        // Sometimes numberValue comes in temporarily as undefined, so show '--' as a placeholder
        const numberValue = dataPoint?.[0] || '--';
        const unit = dataPoint?.[1] || '';

        return numberValue && unit ? (
            <Card key={key}>
                <Label>{ValuesToRender[key]}</Label>
                <StyledNumber>
                    {numberValue}
                    <StyledUnit>{unit}</StyledUnit>
                </StyledNumber>
            </Card>
        ) : null;
    };

    return (
        <>
            {!data.download ? (
                <CardWrapper>
                    <LoadingIndicator />
                    <SubTitle>Performing connection test...</SubTitle>
                </CardWrapper>
            ) : (
                <>
                    <SubTitle>Internet Speed</SubTitle>
                    <CardWrapper>
                        {Object.values(ValuesToRender).map(key =>
                            renderCard(key)
                        )}
                    </CardWrapper>
                </>
            )}
        </>
    );
};

export default SpeedTest;
