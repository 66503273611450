import { configureStore, MiddlewareArray } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import rootReducer from './index'

const devMiddleware = new MiddlewareArray()
const isDev = process.env.NODE_ENV !== 'production'

if (isDev) {
  const { createLogger } = require('redux-logger')
  devMiddleware.prepend(createLogger({ diff: false }))
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState: {},
  devTools: isDev,
  middleware: getDefaultMiddleware =>
    isDev
      ? getDefaultMiddleware().concat(devMiddleware)
      : getDefaultMiddleware()
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
