import { useTheme } from '@emotion/react';
import { FallbackProps } from 'react-error-boundary';
import * as S from './styles';

const ErrorBoundaryFallback = (props: FallbackProps) => {
    const theme = useTheme();

    return (
        <S.Container>
            <S.Title>Oops, something went wrong</S.Title>
            <pre>Error: {props.error.toString()}</pre>
            <S.Subcontainer>
                <S.Text>
                    An unknown error occurred. Please try refreshing the screen
                    by selecting the button below, or contact support if the
                    issue persists.
                </S.Text>

                <S.Label>Please call support at</S.Label>
                <S.Text>(866) 868-2146</S.Text>
            </S.Subcontainer>

            <S.Button
                theme={theme}
                type="button"
                onClick={() => window.location.reload()}
            >
                Refresh
            </S.Button>
        </S.Container>
    );
};

export default ErrorBoundaryFallback;
