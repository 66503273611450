import { combineReducers } from '@reduxjs/toolkit';
import {
    layout,
} from './slices';

const rootReducer = combineReducers({
    layout: layout.layoutReducer,
});

export * from './slices/layout';

export default rootReducer;
