import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { prop } from 'lodash/fp'
import { RootState } from '../store'

interface LayoutState {
  headerHeight: number
  sectionHeaderHeight: number
  itemHeight: number
  paginationHeight: number
  footerCTAHeight: number
}

const initialState: LayoutState = {
  headerHeight: 0,
  sectionHeaderHeight: 0,
  itemHeight: 166,
  paginationHeight: 72,
  footerCTAHeight: 0
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setHeaderHeight (state: LayoutState, action: PayloadAction<number>) {
      state.headerHeight = action.payload
    },
    setSectionHeaderHeight (state: LayoutState, action: PayloadAction<number>) {
      state.sectionHeaderHeight = action.payload
    },
    setItemHeight (state: LayoutState, action: PayloadAction<number>) {
      state.itemHeight = action.payload
    },
    setPaginationHeight (state: LayoutState, action: PayloadAction<number>) {
      state.paginationHeight = action.payload
    },
    setFooterCTAHeight (state: LayoutState, action: PayloadAction<number>) {
      state.footerCTAHeight = action.payload
    }
  }
})

export const getLayoutMeasurements = (state: RootState) => state.layout

export const getHeaderHeight = createSelector(
  [getLayoutMeasurements],
  layout => layout.headerHeight
)
export const getSectionHeaderHeight = createSelector(
  [getLayoutMeasurements],
  layout => layout.sectionHeaderHeight
)
export const getItemHeight = createSelector(
  [getLayoutMeasurements],
  layout => layout.itemHeight
)
export const getPaginationHeight = createSelector(
  [getLayoutMeasurements],
  layout => layout.paginationHeight
)
export const getFooterCTAHeight = createSelector(
  [getLayoutMeasurements],
  layout => layout.footerCTAHeight
)

export const {
  setHeaderHeight,
  setSectionHeaderHeight,
  setItemHeight,
  setPaginationHeight,
  setFooterCTAHeight
} = layoutSlice.actions
export const layoutReducer = layoutSlice.reducer
